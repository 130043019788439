import qs from 'query-string';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { ApproverFilter, ConclusionFilter, CustomFieldFilter, DateFilter, InspectionTypesFilter, InspectorFilter, SupplierFilter } from '.';
import { getShowFiltersSelector, setLayoutAttribute, showFilters } from '../../../base/baseSlice';
import RemoveIcon from '../../../base/components/basic/RemoveIcon';
import { Breakpoints } from '../../../base/config';
import { Context } from '../../../base/types';
import { getLocationEntry, isMobileDevice, twMerge, useMaxWidthBreak, useMinWidthBreak } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { getInspectionsListMetaDataSelector, setListViewDefaultDate } from '../../inspections/slices/inspectionsListSlice';
import { useHasIndependentProductionUnits } from '../../productionUnits/hooks/useHasIndependentProductionUnits';
import { hasProductionUnitsCustomFieldsSelector } from '../../productionUnits/slice/productionUnitsSlice';
import { hasSupplierCustomFieldsSelector } from '../../suppliers/slice/suppliersSlice';
import { loadFilters, setFilter } from '../filtersActions';
import { hasActiveFiltersSelector } from '../selectors';
import AssignedUserFilter from './AssignedUserFilter';
import AuditConclusionFilter from './AuditConclusionFilter';
import AuditTypesFilter from './AuditTypeFilter';
import ComplianceRequirementCategoryFilter from './ComplianceRequirementCategoryFilter';
import CorrectiveActionStateFilter from './CorrectiveActionStateFilter';
import ProductionUnitFilter from './ProductionUnitFilter';
import ProductionUnitStatusFilter from './ProductionUnitStatusFilter';
import ResponsibleFilter from './ResponsibleFilter';
import SelectedFiltersNew from './SelectedFiltersNew';
import SupplierCustomFieldsFilter from './SupplierCustomFieldsFilter';
import DocumentationTypesFilter from './DocumentationTypesFilter';
import DocumentationStatesFilter from './DocumentationStatesFilter';
import Button from '../../../base/ui/components/buttons/Button';
import { Sizes } from '../../../base/ui/components/types';
import { getPermissionsSelector } from '../../../base/selectors';
import { useCheckHasAnyOfThesePermissions, useCheckHasPermission } from '../../../base/hooks';
import { DocumentationStatus } from '../../../backend_api/models';

export type FiltersListTypes = (
    'date-filter'
    | 'inspector-filter'
    | 'assigned-user-filter'
    | 'audit-conclusion-filter'
    | 'conclusion-filter'
    | 'approver-filter'
    | 'inspection-types-filter'
    | 'custom-field-filter'
    | 'supplier-filter'
    | 'production-unit-filter'
    | 'responsible-filter'
    | 'ca-state-filter'
    | 'audit-type-filter'
    | 'compliance-requirements-category-filter'
    | 'supplier-custom-fields-filter'
    | 'production-unit-custom-fields-filter'
    | 'production-unit-status-filter'
    | 'documentation_type'
    | 'documentation_state'
);
type FiltersListProps = {
    className?: string;
    filters: FiltersListTypes[];
    dateFilterChange?();
    getFilterMetaData?(any);
    noStatusAfterClear?: boolean;
    context?: string;
    isFetching?: boolean;
    productionUnitOrSupplier?: 'production_unit' | 'supplier';
};

const FiltersList = (props: FiltersListProps): React.ReactElement => {
    const { filters, className, dateFilterChange, getFilterMetaData, noStatusAfterClear = false, isFetching, productionUnitOrSupplier } = props;
    const history = useHistory();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const locationParsed = qs.parse(location.search as string);
    const query = locationParsed.query;
    const isMobile2 = isMobileDevice();
    const isMobile = useMaxWidthBreak(Breakpoints.SM);
    const isMinLG = useMinWidthBreak(Breakpoints.LG);
    const filtersVisible = useSelector(getShowFiltersSelector);
    const hasActiveFilters = useSelector(hasActiveFiltersSelector);
    const meta: any = useSelector(getFilterMetaData ? getFilterMetaData : getInspectionsListMetaDataSelector);
    const independentProductionUnits = useHasIndependentProductionUnits();
    const filterClass = 'md:w-80 border-b md:border-0 px-4 pt-4 pb-2 text-md';
    const includeSearch = true;
    const showExpanded = isMinLG;
    const hasCustomFieldsProductionUnits = useSelector(hasProductionUnitsCustomFieldsSelector);
    const hasCustomFieldsSuppliers = useSelector(hasSupplierCustomFieldsSelector);
    const documentationTypesFilter = getLocationEntry(location, 'documentation_type');
    const [showCustomFieldFilters, setShowCustomFieldFilters] = React.useState(false);
    const permissions = useSelector(getPermissionsSelector);
    const hasDocumentationTypesPermission = useCheckHasAnyOfThesePermissions(permissions, ['u_production_unit_documentation_types', 'u_supplier_documentation_types']);


    const hasCFFilters = () => {
        if (props.context === Context.Suppliers) {
            return hasCustomFieldsSuppliers;
        }
        if (props.context === Context.ProductionUnits) {
            return hasCustomFieldsProductionUnits;
        }
        return true;
    }
    const hasFilters = isMobile ? true : hasCFFilters();

    useEffect(() => {
        if (history.action === 'POP') {
            dispatch(loadFilters());
        }
    }, [history.location.search]);
    useEffect(() => {
        if (isMobile) {
            dispatch(setLayoutAttribute('bodyFixed', filtersVisible));
        }
    }, [filtersVisible]);

    const filterItems = <>
        {filters.includes('date-filter') && <div className={filterClass}><DateFilter disabled={meta.isFetching} onChange={dateFilterChange ? dateFilterChange : setListViewDefaultDate} showFuture={true} showPast={true} showLabels={true} /></div>}
        {filters.includes('assigned-user-filter') && <div className={filterClass}><AssignedUserFilter search={includeSearch} showExpanded={showExpanded} disabled={meta.isFetching} /></div>}
        {filters.includes('inspector-filter') && <div className={filterClass}><InspectorFilter search={includeSearch} showExpanded={showExpanded} disabled={meta.isFetching} /></div>}
        {filters.includes('conclusion-filter') && <div className={filterClass}><ConclusionFilter search={includeSearch} disabled={meta.isFetching} /></div>}
        {filters.includes('audit-conclusion-filter') && <div className={filterClass}><AuditConclusionFilter search={includeSearch} disabled={meta.isFetching} /></div>}
        {filters.includes('approver-filter') && <div className={filterClass}><ApproverFilter search={includeSearch} showExpanded={showExpanded} disabled={meta.isFetching} /></div>}
        {filters.includes('inspection-types-filter') && <div className={filterClass}><InspectionTypesFilter search={includeSearch} disabled={meta.isFetching} /></div>}
        {filters.includes('custom-field-filter') && <div className={filterClass}><CustomFieldFilter search={includeSearch} disabled={meta.isFetching} /></div>}
        {filters.includes('supplier-filter') && <div className={filterClass}><SupplierFilter search={includeSearch} disabled={meta.isFetching} /></div>}
        {filters.includes('production-unit-filter') && independentProductionUnits && <div className={filterClass}><ProductionUnitFilter search={includeSearch} disabled={meta.isFetching} /></div>}
        {filters.includes('responsible-filter') && <div className={filterClass}><ResponsibleFilter search={includeSearch} disabled={meta.isFetching} /></div>}
        {filters.includes('ca-state-filter') && <div className={filterClass}><CorrectiveActionStateFilter context={props.context} /></div>}
        {filters.includes('audit-type-filter') && <div className={filterClass}><AuditTypesFilter /></div>}

        <div className='flex flex-wrap justify-start w-full'>
            <div className='flex flex-wrap items-start justify-start'>
                {filters.includes('production-unit-status-filter') && <div className={filterClass}><ProductionUnitStatusFilter /></div>}
                {filters.includes('documentation_type') && hasDocumentationTypesPermission &&
                    <div className={filterClass}>
                        <DocumentationTypesFilter
                            type={productionUnitOrSupplier}
                            label='filters.documentation_type'
                            placeholder='filters.select_type'
                            onFilterSet={(selectedTypes) => {
                                dispatch(setFilter("documentation_type", selectedTypes));
                                if(selectedTypes.length == 0) {
                                    dispatch(setFilter('documentation_status', []));
                                    dispatch(setFilter('documentation_expiry_date', []));
                                    dispatch(setFilter('no_documentation', []));
                                }
                                if(documentationTypesFilter?.length == 0) {
                                    dispatch(setFilter("documentation_status", [DocumentationStatus.Active, DocumentationStatus.Approved]));
                                }
                            }}
                            allowMultipleChoice={true}
                        />
                    </div>
                }
                {/* {filters.includes('documentation_state') && documentationTypesFilter?.length > 0 && hasDocumentationTypesPermission && <div className="w-[21.5rem] border-b md:border-0 px-4 pt-4 pb-2 text-md"><DocumentationStatesFilter /></div>} */}
            </div>

            {filters.includes('supplier-custom-fields-filter') || filters.includes('production-unit-custom-fields-filter') &&
                <div className='pt-[2.5rem]'>
                    {
                        showCustomFieldFilters ?
                            <Button size={Sizes.Small} icon='microinteraction_collapse' onPress={() => setShowCustomFieldFilters(false)} label='filters.selected_filters.show_less_filters' labelRight />
                            :
                            <Button size={Sizes.Small} icon='microinteraction_expand' onPress={() => setShowCustomFieldFilters(true)} label='filters.selected_filters.show_more_filters' labelRight />
                    }
                </div>
            }
        </div>
        {filters.includes('compliance-requirements-category-filter') && <div className={filterClass}><ComplianceRequirementCategoryFilter /></div>}
        {filters.includes('supplier-custom-fields-filter') && <div className='w-full first:border-0'><SupplierCustomFieldsFilter type={Context.Suppliers} isFetching={props.isFetching} /></div>}
        {filters.includes('production-unit-custom-fields-filter') && showCustomFieldFilters && <div className='w-full even:border-none'><SupplierCustomFieldsFilter type={Context.ProductionUnits} isFetching={props.isFetching} /></div>}
    </>;
    const close = () => {
        dispatch(showFilters(false));
    }
    const content = <span className={twMerge('filtersList flex', filtersVisible && 'pb-8')}>
        {filtersVisible && <div className={twMerge('flex bg-white border flex-col w-full', isMobile && 'h-screen', props.className)}>
            <div className={twMerge('flex flex-col', isMobile && filtersVisible && 'h-screen w-full fixed top-0 z-20 bg-white left-0')}>
                {isMobile && <div className='flex justify-between px-4 py-2 border-b'><h3 className='m-0 leading-normal'><FormattedMessage id='filters.filters_list.small_screen.header.filters' /></h3>
                    <RemoveIcon onClick={close} />
                </div>}

                <div className={twMerge('flex flex-col sm:flex-row sm:flex-wrap space-x-4 mb-4 -ml-4', isMobile && 'flex-1 overflow-x-hidden my-0', isFetching && 'opacity-75')}>
                    <div></div>
                    {filterItems}
                </div>
                {hasFilters && <div className={twMerge('', isMobile && 'sticky bottom-0')}>
                    <SelectedFiltersNew context={Context.Inspections} isFetching={isFetching} />
                </div>}
            </div>
        </div >}
        {!filtersVisible && (query || hasActiveFilters) && <SelectedFiltersNew context={Context.Inspections} isFetching={isFetching} />}
    </span>

    return content;
};

export default FiltersList;
